<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card elevation="0" tile>
        <v-card-title class="grey lighten-4">
          {{ $t("hr.menu.debit_ticket_transfer_requests") }}
          <v-spacer />
          <id-button @click="onRefreshData">
            <template #icon>
              <v-icon left>mdi-refresh</v-icon>
            </template>
            {{ $t("buttons.refresh") }}
          </id-button>
        </v-card-title>
        <v-data-table
          :headers="table.headers"
          :items="table.data"
          :options.sync="table.options"
          :server-items-length="table.totalItems"
          single-select
          class="elevation-1"
          :loading="table.loading"
          :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
        >
          <template slot="item" slot-scope="row">
            <tr :key="row.item.id">
              <td class="text-xs-left no-wrap">
                <employee-profile :employee="row.item.requester" />
              </td>
              <td class="text-xs-left no-wrap">
                {{ row.item.debitTicketToBeTransferred.asset.name || "-" }}
              </td>
              <td class="text-center">
                <request-status-label :status="row.item.debitTransferRequestStatus" />
              </td>
              <td class="text-no-wrap">
                {{ row.item.createdDateTime | formattedDateTimeMinute }}
              </td>
              <td class="text-right">
                <v-btn
                  :disabled="disableActionButton(row.item.debitTransferRequestStatus)"
                  small
                  depressed
                  color="primary"
                  @click.stop="onActionClick(row.item)"
                >
                  {{ $t("buttons.action") }}
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <transfer-request-approval-dialog
      v-model="dialogStatus"
      :transfer-request="selectedTransferRequest"
      @success="onRefreshData"
    />
  </v-row>
</template>

<script>
  import * as GQL from "./query";

  export default {
    name: "TransferRequestApproval",
    components: {
      TransferRequestApprovalDialog: () => import("./components/TransferRequestApprovalDialog"),
      RequestStatusLabel: () => import("@/components/global/request-status-label")
    },
    data: vm => ({
      dialogStatus: false,
      selectedTransferRequest: null,
      table: {
        loading: false,
        data: [],
        criteria: {
          approver: vm.$store.state.auth.user.id
        },
        options: {},
        pagination: {
          rowsPerPage: 50,
          sortBy: "createdDateTime",
          descending: true
        },
        totalItems: 0,
        headers: [
          {
            text: vm.$t("hr.debit_ticket_transfer_request.requester"),
            align: "left",
            sortable: false,
            value: "",
            class: ["primary--text text-lg-left"]
          },
          {
            text: vm.$t("hr.debit_ticket_transfer_request.asset"),
            align: "left",
            sortable: false,
            value: "",
            class: ["primary--text text-lg-left"]
          },
          {
            text: vm.$t("global.status"),
            align: "center",
            sortable: false,
            value: "",
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.payroll.created_date"),
            align: "start",
            sortable: false,
            value: "",
            class: ["primary--text"]
          },
          {
            text: "",
            align: "left",
            sortable: false,
            value: "",
            class: ["primary--text text-right"]
          }
        ]
      }
    }),
    watch: {
      "table.options": {
        handler(options) {
          let { itemsPerPage, page } = options;
          this.table.pagination.rowsPerPage = itemsPerPage;
          this.table.pagination.page = page;
          this.fetchItems();
        },
        deep: true
      }
    },
    methods: {
      onRefreshData() {
        this.fetchItems();
      },
      async fetchItems() {
        this.table.loading = true;
        await this.$apollo
          .query({
            query: GQL.PAGINATE,
            variables: {
              criteria: this.table.criteria,
              pagination: this.$helpers.convertPagination(this.table.pagination)
            },
            fetchPolicy: "no-cache",
            errorPolicy: "all"
          })
          .then(({ data, errors }) => {
            if (!errors && !data.error) {
              this.table.data = data.filterWithPaginateDebitTicketTransferRequests.content || [];
              this.table.totalItems = data.filterWithPaginateDebitTicketTransferRequests.pageInfo.totalElements || 0;
            }
          })
          .catch(e => {
            this.$helpers.showNotification(e.message);
          })
          .finally(() => {
            this.table.loading = false;
          });
      },
      onActionClick(transferRequest) {
        this.dialogStatus = true;
        this.selectedTransferRequest = transferRequest;
      },
      disableActionButton(status) {
        return status && status !== this.$enums.DEBIT_REQUEST_STATUS.PENDING;
      }
    }
  };
</script>

<style scoped></style>
