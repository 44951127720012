import gql from "graphql-tag";

export const PAGINATE = gql`
  query filterWithPaginateDebitTicketTransferRequests(
    $criteria: DebitTicketTransferRequestFilterCriteria!
    $pagination: Pagination!
  ) {
    filterWithPaginateDebitTicketTransferRequests(criteria: $criteria, pagination: $pagination) {
      pageInfo {
        totalElements
      }
      content {
        id
        debitTicketToBeTransferred {
          asset {
            name
          }
          quantity
          borrowDate
          dueDate
          giveBackDate
        }
        requester {
          firstName
          lastName
          profile {
            imageUrl
          }
          employment {
            branch {
              name
            }
          }
        }
        approver {
          firstName
          lastName
          profile {
            imageUrl
          }
        }
        status
        debitTransferRequestStatus
        description
        createdDateTime
      }
    }
  }
`;
